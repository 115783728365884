import Layout from 'components/Layout';
import React from 'react';
import BasicSection from 'components/BasicSection';
import { isBrowser } from 'utils/is-browser';

const NotFound: React.FC = () => {
    // This is needed to avoid a flash of the 404 when opening /download
    // https://github.com/gatsbyjs/gatsby/issues/5329#issuecomment-484741119
    if (!isBrowser) return <></>;

    return (
        <Layout title="Ente - 404">
            <BasicSection>
                <BasicSection.Header>
                    <BasicSection.Heading>four-oh-four</BasicSection.Heading>
                </BasicSection.Header>
                <div className="d-flex flex-column gap-4 my-4 mx-2 text-center">
                    <div className="lead">
                        If you cannot find what you're looking for, it's
                        sometimes better to look inside.
                    </div>
                </div>
            </BasicSection>
        </Layout>
    );
};

export default NotFound;
